<template>
  <div class="csn-mmg">
    <section class="section-menu-buttons">
      <div class="row-fluid">
        <div class="col-md-6 csn-mm-header">
          <a @click="returnToMainMenu">
            <SlideArrow :isBack="true" />
          </a>
        </div>
        <div class="col-md-6 text-right csn-mm-header">
          <a class="close-btn" @click="TOGGLE_MAIN_MENU">
            <CloseIcon />
          </a>
        </div>
      </div>
    </section>

    <div class="main-menu csn-mm-list">
      <ul>
        <li
          v-for="(name, key) in language"
          :key="key"
          @click="selectLanguage(key)"
          class="csn-mm-list-li"
          :class="{
            'csn-mm-list-li-selected': defineIsSelected({
              key,
              locale: CURRENT_LANGUAGE,
            }),
          }"
        >
          <a class="csn-mm-list-route csn-mm-list-a">{{ name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  Module,
  MAIN_MENU_LANGUAGE,
  TOGGLE_MAIN_MENU,
  TOGGLE_MAIN_MENU_COMPONENT,
  MAIN_MENU,
  Language as LanguageEnum,
  CURRENT_LANGUAGE,
} from '@/constants'
import { changeLanguage } from '@/helpers'

export default {
  name: MAIN_MENU_LANGUAGE,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  computed: {
    ...mapState(Module.LANGUAGE, [CURRENT_LANGUAGE]),
    language: () => LanguageEnum,
    selectedLanguage() {
      return this.CURRENT_LANGUAGE.toUpperCase()
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_MAIN_MENU,
      TOGGLE_MAIN_MENU_COMPONENT,
    ]),
    returnToMainMenu() {
      this.TOGGLE_MAIN_MENU_COMPONENT({ value: MAIN_MENU })
    },
    defineIsSelected: ({ key, locale }) => key === locale,
    selectLanguage(key) {
      this.TOGGLE_MAIN_MENU()
      changeLanguage(key)
    },
  },
}
</script>
